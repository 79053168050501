// import { UserCredential } from "@/model/UserCredential";
import { async } from "@firebase/util";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getStorage, ref, getDownloadURL,  uploadBytes} from "firebase/storage";
import {v4 as uuidv4} from 'uuid'



let app, auth, storage

export function init() {
    const firebaseConfig = {
        apiKey: "AIzaSyB_Phqtym8PgxJRlw3rBZsPq5wxb-TE2wU",
        authDomain: "v-art-marketplace.firebaseapp.com",
        databaseURL: "https://v-art-marketplace-default-rtdb.firebaseio.com",
        storageBucket: "v-art-marketplace.appspot.com",
    };
    app = initializeApp(firebaseConfig);
    auth = getAuth();
    storage = getStorage();
}

export async function signIn(credentials) {
    return signInWithEmailAndPassword(auth, credentials.email, credentials.password)
}

export async function getImagePreview(id) {
    return getDownloadURL(ref(storage, 'artwork-preview/' + id))
}

export async function getUserById(id) {
    // return getAuth().getUser(id)
}

export async function uploadImage(image){
    const uuid = uuidv4()
    const storageRef = ref(storage, 'collection/' + uuid);
    return uploadBytes(storageRef, image)
}