<template>
  <v-container>
      <v-row justify="center" class="mt-8">
         <v-progress-circular
         v-if="isLoading"
      :size="70"
      :width="7"
      color="blue darken-4"
      indeterminate
    ></v-progress-circular>
    </v-row>
    <v-row v-if="!isLoading">
        <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        outlined dense
      ></v-text-field>
    </v-row>
    <v-row>
    <v-data-table v-if="!isLoading"
      :headers="headers"
      :items="products"
      :items-per-page="-1"
      :search="search"
      :item-class="approvedClass"
      class="elevation-1"
    >
    
      <template v-slot:[`item.details`]="{ item }">
        <v-btn
          class="mx-2"
          dark
          small
          color="blue darken-4"
          @click="showDetails(item._id)"
        >
          <v-icon dark>mdi-card-account-details</v-icon>
        </v-btn>
      </template></v-data-table
    >
    </v-row>
  </v-container>
</template>

<script>
import vArtApi from "../services/v-art";
import { getImagePreview, init, getUserById } from "../services/firebase";
export default {
  data() {
    return {
      isLoading: true,
      search: '',
      headers: [
        { text: "ID", value: "_id"},
        { text: "Name", value: "name" },
        { text: "Artist", value: "creatorName" },
        { text: "Blockchain", value: "blockchain" },
        { text: "Price", value: "price" },
        { text: "Details", value: "details" },
      ],
      products: [],
    };
  },

  async created() {
    init();
    const type = this.$route.params.type;
    this.updateProducts(type);
  },

  async beforeRouteUpdate(to, from, next) {
    const type = to.params.type;
    this.updateProducts(type);
    next();
  },
  methods: {
    approvedClass(item){
      if(!item.approved){
        return "red-row"
      } else if (item.approved.approved == false){
return "red-row"
      }
      
      else {
        return ''
      }
      // return ''
    },
    showDetails(id) {
      this.$router.push({ name: "product", params: { id: id } });
    },
    async updateProducts(type) {
      try {
      this.isLoading = true
      this.products = [];
      if (type == "all") {
        console.log("loading all");
        const products = await (await vArtApi.getAllProducts()).data;

        const productWithNames = await Promise.all(
          products.map(async (obj) => {
            const creatorName = await vArtApi.getUserById(obj.createdBy);
            return {
              ...obj,
              creatorName: creatorName.data.name,
            };
          })
        );

        this.isLoading = false
        this.products = productWithNames;
      } else if (type == "unapproved") {
        console.log("loading unapproved");
        const products = await (await vArtApi.getUnapprovedProducts()).data;
        const productWithNames = await Promise.all(
          products.map(async (obj) => {
            const creatorName = await vArtApi.getUserById(obj.createdBy);
            return {
              ...obj,
              creatorName: creatorName.data.name,
            };
          })
        );
        this.isLoading = false
        this.products = productWithNames;
      } else if (type == "approved") {
        console.log("loading approved");
        const products = await (await vArtApi.getAllProducts()).data;
        const productWithNames = await Promise.all(
          products.map(async (obj) => {
            const creatorName = await vArtApi.getUserById(obj.createdBy);
            return {
              ...obj,
              creatorName: creatorName.data.name,
            };
          })
        );
        this.isLoading = false
        this.products = productWithNames;
      } else if (type == "bought") {
        console.log("loading bought");
        const products = await (await vArtApi.getAllProducts()).data;
        const productWithNames = await Promise.all(
          products.map(async (obj) => {
            const creatorName = await vArtApi.getUserById(obj.createdBy);
            return {
              ...obj,
              creatorName: creatorName.data.name,
            };
          })
        );
        this.isLoading = false
        this.products = productWithNames.filter(p => p.owner != p.author);
      } else if (type == "approve-not-true") {
        console.log("loading approve-not-true");
        const products = await (await vArtApi.getAllProducts()).data;
        const productWithNames = await Promise.all(
          products.map(async (obj) => {
            const creatorName = await vArtApi.getUserById(obj.createdBy);
            return {
              ...obj,
              creatorName: creatorName.data.name,
            };
          })
        );
        this.isLoading = false
        console.log(productWithNames);
        this.products = productWithNames
      }
      }
      catch {
        this.$router.push('/auth')
      }
    },
    
  },
};
</script>

<style lang="scss" >
// .red-row {
//   background-color: rgb(223, 183, 183);
// }
</style>