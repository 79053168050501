<template>
  <v-container>
    <h2>Select data on left side</h2>
    <!-- <v-row justify="center" class="mt-5">
      <v-btn-toggle v-model="tab" mandatory>
        <v-btn color="blue darken-4" outlined dense small>All</v-btn>
        <v-btn color="blue darken-4" outlined dense small>Approved</v-btn>
        <v-btn color="blue darken-4" outlined dense small>Non-approved</v-btn>
        <v-btn color="blue darken-4" outlined dense small>Approve != true</v-btn>
        <v-btn color="blue darken-4" outlined dense small>Owner != author</v-btn>
        <v-btn color="blue darken-4" outlined dense small>Links</v-btn>
      </v-btn-toggle>
    </v-row>
    <v-row v-if="isLoading" justify="center" class="mt-5">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-row>
    <v-row v-if="!isLoading" justify="center">
      <products-table :products=products></products-table>
    </v-row>


    <v-simple-table v-if="false">
      
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">ID</th>
            <th class="text-left">Name</th>
            <th class="text-left">Artist</th>
            <th class="text-left">Blockchain</th>
            <th class="text-left">Price</th>
            <th class="text-left">Details</th>
            <th class="text-left">Details</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="p in products" :key="p._id">
            <td>{{ p._id }}</td>
            <td>{{ p.name }}</td>
            <td>{{ p.creatorName }}</td>
            <td>{{ p.blockchain }}</td>
            <td>{{ p.price }}</td>

            <td>
              <v-btn
                color="blue"
                dark
                @click="
                  $router.push({ name: 'product', params: { id: p._id } })
                "
                >OPEN</v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table> -->
  </v-container>
</template>

<script>
import vArtApi from "../services/v-art";
import { getImagePreview, init, getUserById } from "../services/firebase";
export default {
  data() {
    return {
      isLoading: true,
      tab: 1,
      items: ['All', "Approved"]
      // active:null,
      // user: null,
      // products: [],
      // headers: ["_id"],
      // items: [],
      // productsHeaders: [
       
      // ]

      // headers: [
      // { text: "ID" },
      // { text: "Image"},
      // { text: "Approve"},
      // ],
    };
  },
  // computed: {},
  watch: {
   async tab(newTab, oldTab){
      console.log(oldTab + ' >>> ' + newTab);
      // if(newTab == 0){
          // const products = await (await vArtApi.getAllProducts()).data;
          // this.items = products
          // this.headers = this.productsHeaders
          // console.log(products);
      // } else if(newTab == 1){
          // console.log(1);
      // }
      // this.isLoading = false
    }
  },
  methods: {
    changeView(){
      console.log(this.tab);
    },
    onButtonClick(item){
        console.log(item);
    }
    //  async getUser(userId){
    //  const x = await     vArtApi.getUserById(userId)
    //  console.log(x.data.name);
    // }
  },

  async created() {
    this.user = JSON.parse(localStorage.user);
    // console.log(this.user._tokenResponse.idToken);
    if (!this.user) {
      this.$router.push("/auth");
    }
    // init();
    // const products = await (await vArtApi.getUnapprovedProducts()).data;
    // const products = await (await vArtApi.getAllProducts()).data;
    // this.products = products
    // this.isLoading = false
    
    // await products.forEach(async (p) => {
    //   // const name = await vArtApi.getUserById(p.createdBy)
    //   // p["test"] = 'name'

    //     this.products.find(a => {return a._id == p._id})["test"] = 'name'

    // })

    // const r = await Promise.all(
    //   products.map(async (obj) => {
    //     const creatorName = await vArtApi.getUserById(obj.createdBy);
    //     return {
    //       ...obj,
    //       creatorName: creatorName.data.name,
    //     };
    //   })
    // );

    // console.log(r);

    // this.products = r;
    // await this.loadPics()
    // this.products = [{_id: "aa"}]

    // vArtApi
    //   .getAllProducts()
    //   .then((resp) => {
    //     this.products = resp.data;
    //   })
    //   .catch((err) => {
    //     console.log("error while getting pruducts");
    //     console.log(err);
    //   });
    // console.log("prod");
  },
};
</script>
