<template>

  <v-app>
    
    <v-app-bar app flat color="blue darken-4" dark>
      <v-icon> mdi-shield-crown </v-icon>
      <v-toolbar-title> NFT Admin </v-toolbar-title>
    </v-app-bar>
   


       <v-navigation-drawer app absolute clipped > 
      <v-list nav>
        <v-list-item link @click="$router.push({name: 'products', params: {type: 'all'}})">All products</v-list-item>
        <v-list-item link @click="$router.push({name: 'products', params: {type: 'unapproved'}})">Unapproved products</v-list-item>
        <v-list-item link @click="$router.push({name: 'products', params: {type: 'approved'}})">Approved products</v-list-item>
        <v-list-item link @click="$router.push({name: 'products', params: {type: 'bought'}})">Bought products</v-list-item>
        <v-list-item link @click="$router.push({name: 'products', params: {type: 'approve-not-true'}})">Approve not true</v-list-item>
        <v-list-item link @click="$router.push({name: 'products-links'})">Links</v-list-item>
        <v-list-item link @click="$router.push({name: 'users', params: {type: 'all'}})">All users</v-list-item>
        <v-list-item link @click="$router.push({name: 'new-collection'})">New collection</v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>

      <router-view />
    </v-main>
  </v-app>
  <!-- <v-app>
<header-component></header-component>

  <v-main>
    <router-view/>
  </v-main>
  </v-app> -->
</template>

<script>
export default {
  data(){
    return {
      items: [
      {title: "1"},
      {title: "2"},
      ]
    }
  },
  components: {
  }
}
</script>


