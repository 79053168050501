import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AuthView from '../views/AuthView.vue'
import ProductCard from '../views/ProductCard.vue'
import ProductsView from '../views/ProductsView.vue'
import UsersView from '../views/UsersView.vue'
import ProductsLinksView from '../views/ProductsLinksView.vue'
import CreateCollectionView from '../views/CreateCollectionView.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthView
  },
  {
    path: '/products/:type',
    name: 'products',
    component: ProductsView
  },
  {
    path: '/users/:type',
    name: 'users',
    component: UsersView
  },
  {
    path: '/products-links',
    name: 'products-links',
    component: ProductsLinksView
  },
  {
    path: '/product/:id',
    name: 'product',
    component: ProductCard
  },
  {
    path: '/new-collection',
    name: 'new-collection',
    component: CreateCollectionView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
