export const auth = {
    namespaced: true,
    state: {
        user: null
    },
    getters: {
        USER: state =>  {
            return state.user
        }
    },
    mutations: {
        SET_USER(state, payload) {
            state.user = payload
        }
    },
    actions() {
        
    }
}