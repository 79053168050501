<template>
  <v-container>
    <v-row justify="center" class="mt-8">
      <v-progress-circular
        v-if="isLoading"
        :size="70"
        :width="7"
        color="blue darken-4"
        indeterminate
      ></v-progress-circular>
    </v-row>
  <v-row v-if="!isLoading">
        <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        outlined dense
      ></v-text-field>
    </v-row>
    <v-row>
      <v-data-table
        v-if="!isLoading"
        :headers="headers"
        :items="products"
        :items-per-page="-1"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:[`item.link`]="{ item }">
          <a :href="'https://v-art.digital/product/' + item._id">Link to V-ART</a>
        </template></v-data-table
      >
    </v-row>
  </v-container>
</template>

<script>
import vArtApi from "../services/v-art";
import { getImagePreview, init, getUserById } from "../services/firebase";
export default {
  data() {
    return {
      isLoading: true,
      search: "",
      headers: [
        { text: "ID", value: "_id" },
        { text: "Name", value: "name" },
        { text: "Artist", value: "creatorName" },
        { text: "Link", value: "link" },
      ],
      products: [],
    };
  },
  async created() {
    init();
    const products = await (await vArtApi.getAllProducts()).data;

        // const productWithNames = await Promise.all(
        //   products.map(async (obj) => {
        //     const creatorName = await vArtApi.getUserById(obj.createdBy);
        //     return {
        //       ...obj,
        //       creatorName: creatorName.data.name,
        //     };
        //   })
        // );

        this.isLoading = false
        // this.products = productWithNames;
        this.products = products;
    // const type = this.$route.params.type;
    // this.updateProducts(type);
  },
};
</script>

<style>
</style>