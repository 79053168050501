<template>
  <v-container>
    <v-row justify="center" class="mt-8">
      <v-progress-circular
        v-if="isLoading"
        :size="70"
        :width="7"
        color="blue darken-4"
        indeterminate
      ></v-progress-circular>
    </v-row>
    <v-row v-if="!isLoading">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        outlined
        dense
      ></v-text-field>
    </v-row>
    <v-row>
      <v-data-table
        v-if="!isLoading"
        :headers="headers"
        :items="users"
        :items-per-page="-1"
        :search="search"
        class="elevation-1"
         :item-class="approvedClass"
      >
        <template v-slot:[`item.approve`]="{ item }">
          <v-btn
            class="mx-2"
            dark
            small
            color="blue darken-4"
            @click="approve(item._id)"
          >
            <v-icon dark>mdi-plus-circle</v-icon>
          </v-btn>
        </template></v-data-table
      >
    </v-row>
  </v-container>
</template>

<script>
import vArtApi from "../services/v-art";
import { getImagePreview, init, getUserById } from "../services/firebase";
export default {
  data() {
    return {
      isLoading: true,
      search: "",
      headers: [
        { text: "ID", value: "_id" },
        { text: "Name", value: "userName" },
        { text: "Email", value: "email" },
        { text: "Updated At", value: "updatedAt" },
              { text: "Approve", value: "approve" },
      ],
      products: [],
      users: [],
    };
  },

  async created() {
    init();
    const type = this.$route.params.type;
    this.updateUsers(type);
  },

  async beforeRouteUpdate(to, from, next) {
    console.log("bru");
    const type = to.params.type;
    this.approveUser(type);
    next();
  },
  methods: {
     approvedClass(item){
      if(!item.approved){
        return "red-row"
      } else {
        return ''
      }
      // return ''
    },
    approve(id){
      vArtApi.approveUser(id)
    },
    showDetails(id) {
      // this.$router.push({ name: "product", params: { id: id } });
    },
    async updateUsers(type) {
      console.log("updating users");
      this.isLoading = true;
      this.users = [];
      if (type == "all") {
        console.log("updating users - ALL");
        const users = await vArtApi.getAllUsers()
        this.users = users.data;
      }
      this.isLoading = false;
    },
    // console.log("loading all");
    // const products = await (await vArtApi.getAllProducts()).data;

    //   const productWithNames = await Promise.all(
    //     products.map(async (obj) => {
    //       const creatorName = await vArtApi.getUserById(obj.createdBy);
    //       return {
    //         ...obj,
    //         creatorName: creatorName.data.name,
    //       };
    //     })
    //   );

    //   this.isLoading = false
    //   this.products = productWithNames;
    // } else if (type == "unapproved") {
    //   console.log("loading unapproved");
    //   const products = await (await vArtApi.getUnapprovedProducts()).data;
    //   const productWithNames = await Promise.all(
    //     products.map(async (obj) => {
    //       const creatorName = await vArtApi.getUserById(obj.createdBy);
    //       return {
    //         ...obj,
    //         creatorName: creatorName.data.name,
    //       };
    //     })
    //   );
    //   this.isLoading = false
    //   this.products = productWithNames;
    // } else if (type == "approved") {
    //   console.log("loading approved");
    //   const products = await (await vArtApi.getAllProducts()).data;
    //   const productWithNames = await Promise.all(
    //     products.map(async (obj) => {
    //       const creatorName = await vArtApi.getUserById(obj.createdBy);
    //       return {
    //         ...obj,
    //         creatorName: creatorName.data.name,
    //       };
    //     })
    //   );
    //   this.isLoading = false
    //   this.products = productWithNames;
    // } else if (type == "bought") {
    //   console.log("loading bought");
    //   const products = await (await vArtApi.getAllProducts()).data;
    //   const productWithNames = await Promise.all(
    //     products.map(async (obj) => {
    //       const creatorName = await vArtApi.getUserById(obj.createdBy);
    //       return {
    //         ...obj,
    //         creatorName: creatorName.data.name,
    //       };
    //     })
    //   );
    //   this.isLoading = false
    //   this.products = productWithNames.filter(p => p.owner != p.author);
    // } else if (type == "approve-not-true") {
    //   console.log("loading approve-not-true");
    //   const products = await (await vArtApi.getAllProducts()).data;
    //   const productWithNames = await Promise.all(
    //     products.map(async (obj) => {
    //       const creatorName = await vArtApi.getUserById(obj.createdBy);
    //       return {
    //         ...obj,
    //         creatorName: creatorName.data.name,
    //       };
    //     })
    //   );
    //   this.isLoading = false
    //   console.log(productWithNames);
    //   this.products = productWithNames
  },
};
</script>

<style lang='scss' >
.red-row {
  background-color: rgb(248, 150, 150);
}
</style>