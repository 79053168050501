<template>
  <v-container>
    <v-row>
      <v-col>
        <v-text-field
          outlined
          dense
          placeholder="Enter collection name"
          v-model="collectionName"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea
          outlined
          dense
          placeholder="Enter collection description"
          v-model="collectionDescription"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-file-input
          outlined
          dense
          truncate-length="15"
          label="Select collection picture"
          v-model="collectionImage"
        ></v-file-input>
      </v-col>
    </v-row>

    <!-- <v-row>
      <v-col> Products: {{ selectedProducts }} </v-col>
    </v-row> -->
    <v-row>
      <v-col>
        <v-btn block color="blue darken-4" dark @click="addCollection()">
          Create collection</v-btn
        >
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-8">
      <v-progress-circular
        v-if="isLoading"
        :size="70"
        :width="7"
        color="blue darken-4"
        indeterminate
      ></v-progress-circular>
    </v-row>

    <v-row v-if="!isLoading">
      <v-col cols="12" sm="6">
        <h3>All products</h3>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          outlined
          dense
        ></v-text-field>
        <v-data-table
          v-if="!isLoading"
          :headers="headers"
          :items="products"
          :items-per-page="-1"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:[`item.details`]="{ item }">
            <v-btn
              class="mx-2"
              dark
              small
              color="blue darken-4"
              @click="addProduct(item)"
            >
              <v-icon dark>mdi-arrow-right-bold</v-icon>
            </v-btn>
          </template></v-data-table
        >
      </v-col>
      <v-col cols="12" sm="6">
         <h3>Products to new collection</h3>
        <v-text-field
          v-model="searchSelected"
          append-icon="mdi-magnify"
          label="Search"
          outlined
          dense
        ></v-text-field>
        <v-data-table
          v-if="!isLoading"
          :headers="headers"
          :items="selectedProducts"
          :items-per-page="-1"
          :search="searchSelected"
          class="elevation-1"
        >
          <template v-slot:[`item.details`]="{ item }">
            <v-btn
              class="mx-2"
              dark
              small
              color="blue darken-4"
              @click="removeProduct(item)"
            >
              <v-icon dark>mdi-arrow-left-bold</v-icon>
            </v-btn>
          </template></v-data-table
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import vArtApi from "../services/v-art";
import { v4 as uuidv4 } from "uuid";
import { init, uploadImage } from "../services/firebase";
export default {
  data() {
    return {
      collectionImage: null,
      collectionName: "",
      collectionDescription: "",
      selectedProducts: [],
      products: [],
      headers: [
        { text: "ID", value: "_id" },
        { text: "Name", value: "name" },
        { text: "Artist", value: "creatorName" },
        { text: "Blockchain", value: "blockchain" },
        { text: "Price", value: "price" },
        { text: "Details", value: "details" },
      ],
      search: "",
      searchSelected: "",
      isLoading: true,
    };
  },
  async created() {
    console.log("creat");
    init();
    await this.loadProducts();
  },
  methods: {
    async removeProduct(item){
      const index = this.selectedProducts.indexOf(item)
         if ( index!= -1) {
          console.log('removing');
          this.selectedProducts.splice(index, 1)
      }
    },
    async loadProducts() {
      console.log("loading approved products");
      const products = await (await vArtApi.getAllProducts()).data;
      const productWithNames = await Promise.all(
        products.map(async (obj) => {
          const creatorName = await vArtApi.getUserById(obj.createdBy);
          return {
            ...obj,
            creatorName: creatorName.data.name,
          };
        })
      );
      this.products = productWithNames;
      this.isLoading = false;
    },
    addProduct(item) {
      if (this.selectedProducts.indexOf(item) === -1) {
        this.selectedProducts.push(item);
      }
      // this.selectedProducts.push(item)
      // if (!this.selectedProducts.includes(id)) {
      //   this.selectedProducts.push(id);
      // }
    },
    async addCollection() {

      const products = []
      this.selectedProducts.forEach(p => {
        products.push(p._id)
      })

      // console.log(typeof this.collectionImage);
      // console.log(this.collectionImage);
      const uploadedImage = await uploadImage(this.collectionImage);
      console.log(uploadedImage);
      const metadata = {
        picture: uploadedImage.metadata.name,
        name: this.collectionName,
        description: this.collectionDescription,
        products: products,
      };
      // console.log(metadata);

      // console.log(metadata);
      const created = await vArtApi.createCollection(metadata);
      console.log(created);
    },
  },
};
</script>

<style>
</style>