import axios from "axios"



// let apiUrl = 'https://vast-inlet-22233.herokuapp.com/development/api/v1/'
// let apiUrl = 'https://young-journey-73255.herokuapp.com/development/api/v1/'
let apiUrl = 'https://prodback.v-art.digital:3000/production/api/v1/'

class vArtApi {

    // setApiToken(token){
    //     apiToken = token
    // }


    async getApprovedProducts() {
        return axios.get(apiUrl + 'product?offset=0&limit=500&approved=true')
    }
    async getUnapprovedProducts() {
        return axios.get(apiUrl + 'product?offset=0&limit=500&approved=false')
    }

    async getAllProducts() {
        return axios.get(apiUrl + 'product?offset=0&limit=500')
    }

    async getProductById(id) {
        return axios.get(apiUrl + 'product/' + id)
    }

    async approveProduct(id) {
        const apiToken = JSON.parse(localStorage.user)._tokenResponse.idToken

        var config = {
            method: 'patch',
            url: apiUrl + 'admin/product/approve/' + id + '?approved=true&tokenId=' + id,
            headers: {
                'Authorization': "bearer " + apiToken
            }
        };
        return axios(config)

    }

    async unapproveProduct(id) {
        const apiToken = JSON.parse(localStorage.user)._tokenResponse.idToken

        var config = {
            method: 'patch',
            url: apiUrl + 'admin/product/approve/' + id + '?approved=false&tokenId=' + id,
            headers: {
                'Authorization': "bearer " + apiToken
            }
        };
        return axios(config)

    }


    // app 628503365fff9ad1c1c712b1
    // unap  6286747f89dbac6c28a7c0a0
    async getUserById(id) {
        const apiToken = JSON.parse(localStorage.user)._tokenResponse.idToken
        return axios.get(apiUrl + 'user/' + id, {
            headers: {
                // Accept: 'accept: */*',
                // Authorization: 'Bearer ' + apiToken
            }
            // })
        })


    }


    async approveUser(id){
        console.log('approve user ' + id);
        const apiToken = JSON.parse(localStorage.user)._tokenResponse.idToken

        var config = {
            method: 'patch',
            url: apiUrl + 'admin/product/approve/' + id + '?approved=true&tokenId=' + id,
            headers: {
                'Authorization': "bearer " + apiToken
            }
        };
        return axios(config)
    }

    async getAllUsers(){
        console.log('getting all users');
        return axios.get(apiUrl + 'user?offset=0&limit=500')
    }

    async createCollection(metadata){
        const apiToken = JSON.parse(localStorage.user)._tokenResponse.idToken

        var config = {
            method: 'post',
            url: apiUrl + 'products/collection',
            data: metadata,
            headers: {
                'Authorization': "bearer " + apiToken
            }
        };
        return axios(config)
    }

    
    // curl -X 'GET' \
    // 'https://young-journey-73255.herokuapp.com/development/api/v1/product?offset=0&limit=100&approved=false' \
    // -H 'accept: */*'
}
export default new vArtApi()