<template>
  <v-container>
    <v-row justify="center">
      <v-simple-table v-if="this.product">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>ID</td>
              <td>{{ product._id }}</td>
            </tr>
            <tr>
              <td>Name</td>
              <td>{{ product.name }}</td>
            </tr>
            <tr>
              <td>Picture</td>
              <td>
                <v-img max-width="300px" contain :src="pictureUrl"></v-img>
              </td>
            </tr>
            <tr>
              <td>Medium</td>
              <td>{{ product.medium }}</td>
            </tr>
            <tr>
              <td>Blockchain</td>
              <td>{{ product.blockchain }}</td>
            </tr>

            <tr>
              <td>Genre</td>
              <td>{{ product.genre }}</td>
            </tr>
            <tr>
              <td>Bio</td>
              <td>{{ product.bio }}</td>
            </tr>
            <tr>
              <td>Year</td>
              <td>{{ product.year }}</td>
            </tr>
            <tr>
              <td>Price</td>
              <td>{{ product.price }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-row>
    <v-row justify="center" class="mt-8">
      <v-btn class="mr-4" color="blue darken-4" dark dense @click="approve()">Approve</v-btn>
      <v-btn class="mr-4" color="blue darken-4" dark dense @click="unapprove()">Unapprove</v-btn>
      <v-btn
       
        color="blue darken-4"
        
        dense
        :disabled="!approved"
        @click="mint()"
        >Mint</v-btn
      >
    </v-row>
  </v-container>
</template>

<script>
import vArtApi from "../services/v-art";
import { init, getImagePreview } from "../services/firebase";
export default {
  data() {
    return {
      product: null,
      pictureUrl: null,
      approved: false,
    };
  },
  async created() {
    init();
    const productId = this.$route.params.id;
    const product = await vArtApi.getProductById(productId);
    this.product = product.data;
    await this.getPicture();
  },
  methods: {
    async getPicture() {
      const pic = await getImagePreview(this.product.picture);
      this.pictureUrl = pic;
    },
    approve() {
      vArtApi.approveProduct(this.product._id).then((resp) => {
        this.approved = true;
        // console.log(resp);
      });
    },
       unapprove() {
      vArtApi.unapproveProduct(this.product._id).then((resp) => {
        this.approved = false;
        // console.log(resp);
      });
    },

    mint() {
      if (this.product.blockchain == "Ethereum") {
         const apiToken = JSON.parse(localStorage.user)._tokenResponse.idToken
         const url = 'https://payment.v-art.digital/eth-mint?productId=' + this.product._id + '&vArtApiToken=' + apiToken
        //  const url = 'http://localhost:8082/nft/#/eth-mint?productId=' + this.product._id + '&vArtApiToken=' + apiToken
        window.open(url, '_blank').focus();
      } else if (this.product.blockchain == "Near") {
        const apiToken = JSON.parse(localStorage.user)._tokenResponse.idToken;
        const url = 'https://payment.v-art.digital/near-mint?productId=' + this.product._id + '&vArtApiToken=' + apiToken
        window.open(url, "_blank").focus();
      } else {
        console.log("Bad blockchain!");
      }

      // 
    },
  },
};
</script>

<style>
</style>