<template>
  <v-container>
    <v-form>
      <v-text-field
        label="Email"
        outlined
        dense
        v-model="form.email"
      ></v-text-field>
      <v-text-field
        label="Password"
        outlined
        dense
        type="password"
        v-model="form.password"
      ></v-text-field>
      <v-row justify="center">
        <v-btn @click="signIn()" color="blue darken-4" dark>Sign In</v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { init, signIn } from "../services/firebase.js";
export default {
  created() {
    init();
    // localStorage.user = null
    localStorage.removeItem("user");
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
        // email: 'antonde@ukr.net',
        // password: 'Qwerty 1'
      },
    };
  },
  computed: {
    // USER() {return this.$store.getters["auth/USER"]}
  },
  methods: {
    async signIn() {
      // console.log('sign in');
      signIn(this.form)
        .then((resp) => {
          localStorage.user = JSON.stringify(resp);
          this.$router.push("/");
        })
        .catch((err) => {
          console.log("err while signIn");
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>